import React, { useState } from "react";
import "./App.css";

const App: React.FC = () => {
  const addNetwork = async () => {
    try {
      const provider = (window as any).ethereum;
      if (!provider) {
        alert("MetaMask is not installed!");
        return;
      }
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x301824", // Hexadecimal of 3151908
            chainName: "t1 Devnet v0.1",
            rpcUrls: ["https://rpc.devnet.t1protocol.com"],
            blockExplorerUrls: ["https://explorer.devnet.t1protocol.com"],
            nativeCurrency: {
              name: "Ethereum",
              symbol: "ETH",
              decimals: 18,
            },
          },
        ],
      });
      alert("t1 Devnet v0.1 added to MetaMask!");
    } catch (error) {
      console.error("Error adding network:", error);
    }
  };

  const [isCopiedPrivateKey, setIsCopiedPrivateKey] = useState(false);
  const [isCopiedRPC, setIsCopiedRPC] = useState(false);
  const [isCopiedWSRPC, setIsCopiedWSRPC] = useState(false);
  const [isCopiedExplorer, setIsCopiedExplorer] = useState(false);
  const [isCopiedChainID, setIsCopiedChainID] = useState(false);

  const copyTextToClipboard = (
    text: string,
    setCopied: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copy status after 2 seconds
    });
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>𝚝𝟷 Devnet Portal</h1>
      </header>
      <main>
        <section>
          <h2>Introduction</h2>
	  <p>Welcome to 𝚝𝟷 devnet portal. Below, we will guide you through a demo and share resources with developer who want to deploy applications on 𝚝𝟷 devnet.</p>
	  <h2>Demo</h2>
	  <p>Imagine you have funds on your favorite rollup. You execute a swap and you want to receive your funds back on Ethereum. Today, you’ll have to run multiple transactions. You will either use risky third party bridges and wait for minutes, or leverage canonical rollup bridges and wait for hours or days while the rollup state change is verified on Ethereum. This friction limits composability in the broader Ethereum ecosystem and fragments liquidity.</p>
          <p>Now, consider a seamless experience where a transaction executed on a rollup can be verified on Ethereum’s L1 in the very next block. No delays for Alice to withdraw her funds back to L1 and no fragmented liquidity. The ecosystem feels unified, applications operate in harmony, and users enjoy near-instant settlement to Ethereum. This is what we’re building with 𝚝𝟷.</p>
	  <p>In this demo, you will be executing trades on T-DEX, the frequent batch auction DEX that’s built on 𝚝𝟷. 𝚝𝟷 devnet is a custom RETH implementation that runs inside Intel TDX. 𝚝𝟷 Devnet currently has 1 second block time. We batch multiple 𝚝𝟷 state roots and send these updated state roots which represents the state of 𝚝𝟷 to Ethereum using rethExEx. Once you get a wallet and execute your trade on T-DEX, you will observe:</p>
          <ul>
            <li>Your assets swapped on 𝚝𝟷</li>
            <li>A new state root submitted from 𝚝𝟷 to L1 in every L1 block</li>
	  </ul>
	<p>The new state root on the L1 represents the state of 𝚝𝟷. Using this state root and valid TEE proof, Alice can withdraw her funds from 𝚝𝟷 back to her Ethereum Externally Owned Account in the next L1 block, with an average of just 6 seconds!</p>
	<p></p>
	<p>Now let’s go through this step-by-step!</p>
        </section>
        <section>
          <h2>Add 𝚝𝟷 to your wallet!</h2>
	  <p>Simply click the button below to add it to MetaMask.</p>
          <button onClick={() => addNetwork()}>Add Network to MetaMask</button>
        </section>

        <section>
          <h3>Get free funds!</h3>
          <ol>
            <li>
	      Copy the private key below.
              <div className="code-block">
                <code>
                  27515f805127bebad2fb9b183508bdacb8c763da16f54e0678b16e8f28ef3fff
                </code>
                <button
                  className="copy-button"
                  onClick={() =>
                    copyTextToClipboard(
                      "27515f805127bebad2fb9b183508bdacb8c763da16f54e0678b16e8f28ef3fff",
                      setIsCopiedPrivateKey
                    )
                  }
                >
                  {isCopiedPrivateKey ? "Done" : "Copy"}
                </button>
              </div>
            </li>
            <li>Open MetaMask and click on your account avatar at the top.</li>
            <li>
              Select <strong>Add account or hardware wallet</strong>.
            </li>
            <li>
              Select <strong>Import account</strong>.
            </li>
            <li>
              Paste the previously copied private key into the input box and click <strong>Import</strong>.
            </li>
            <li>
              You now have access to account with address <code>0xAe95d8DA9244C37CaC0a3e16BA966a8e852Bb6D6</code>.
            </li>
          </ol>
        </section>

        <section>
          <h3>Check out T-DEX!</h3>
          <ol>
            <li>
              Go to the{" "}
              <a
                href="https://explorer.l1.devnet.t1protocol.com/address/0xb4B46bdAA835F8E4b4d8e208B6559cD267851051?tab=logs"
                target="_blank"
                rel="noopener noreferrer"
              >
                the L1 T1stateRoot Contract logs
              </a>{" "}
	      and note the current <code>newStateRoot</code>.
            </li>
            <li>
              Navigate to{" "}
              <a
                href="https://d33ynhssf8imk5.cloudfront.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                T-DEX
              </a>{" "}
              and… swap!
            </li>
            <li>
              Return to the{" "}
              <a
                href="https://explorer.l1.devnet.t1protocol.com/address/0xb4B46bdAA835F8E4b4d8e208B6559cD267851051?tab=logs"
                target="_blank"
                rel="noopener noreferrer"
              >
                L1 T1stateRoot Contract logs
              </a>{", "} and note the updated <code>newStateRoot</code>.
            </li>
          </ol>
	  <p>L1 was notified about your transaction in about 6 seconds since your transaction!</p>
        </section>

        <section>
          <h2>Develop on 𝚝𝟷!</h2>
          <p>All you need to develop your own dApp on 𝚝𝟷 devnet:</p>

          <div className="code-block">
            <code>RPC: https://rpc.devnet.t1protocol.com</code>
            <button
              className="copy-button"
              onClick={() =>
                copyTextToClipboard("https://rpc.devnet.t1protocol.com", setIsCopiedRPC)
              }
            >
              {isCopiedRPC ? "Done" : "Copy"}
            </button>
          </div>

          <div className="code-block">
            <code>WS-RPC: wss://ws.devnet.t1protocol.com</code>
            <button
              className="copy-button"
              onClick={() =>
                copyTextToClipboard("wsss://ws.devnet.t1protocol.com", setIsCopiedWSRPC)
              }
            >
              {isCopiedWSRPC ? "Done" : "Copy"}
            </button>
          </div>

          <div className="code-block">
            <code>Explorer: https://explorer.devnet.t1protocol.com</code>
            <button
              className="copy-button"
              onClick={() =>
                copyTextToClipboard(
                  "https://explorer.devnet.t1protocol.com",
                  setIsCopiedExplorer
                )
              }
            >
              {isCopiedExplorer ? "Done" : "Copy"}
            </button>
          </div>

	  <div className="code-block">
            <code>Chain ID: 3151908</code>
            <button
              className="copy-button"
              onClick={() =>
                copyTextToClipboard("3151908", setIsCopiedChainID)
              }
            >
              {isCopiedChainID ? "Done" : "Copy"}
            </button>
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 𝚝𝟷 Devnet Portal</p>
      </footer>
    </div>
  );
};

export default App;

